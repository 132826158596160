import React from "react";

import Layout from "../components/Layout";
import Title from "../components/Title";

const AboutPage = ({ path }) => {
  return (
    <Layout currentPath={path} title="About Us">
      <Title label="About Tinkertax" />

      <section className="container mx-auto px-4">
        <p className="text-gray-500 text-xl">
          “HOW DO WE MAKE THINGS MORE EFFICIENT FOR EVERYONE?”
        </p>
        <p className="my-4 text-base text-gray-800">
          Tinkertax started when a group of friends came together to solve a
          problem for the business community. How could we help small business
          owners save time and money? How could we allow them to focus on what
          they really need to do – grow their business?
        </p>

        <p className="my-4 text-base text-gray-800 dark:text-gray-300">
          In late 2016, we chanced upon a hackathon competition organised by the
          IRAS (Inland Revenue Authority of Singapore). The objective of the
          competition was to use technology to help the tax paying community.
          With our knowledge of Singapore accounting and tax standards, we
          decided to participate in this competition. To our delight, we
          unexpectedly won the 1st prize!
        </p>

        <p className="text-gray-500 text-xl">
          TINKERTAX: OUR D.I.Y. SOLUTION TO HELP YOU CALCULATE YOUR TAX
        </p>

        <p className="my-4 text-base text-gray-800 dark:text-gray-300">
          Our winning idea was to help small business owners compute and file
          their tax on their own – quickly, accurately and without having to
          understand any of the tax laws in Singapore. Many people we spoke to
          told us they have no clue as to how to calculate and file their taxes.
          Resources online were comprehensive, but required time and effort to
          study. Tax-payers were also afraid of incurring penalties due to
          incorrect filing. As a result, they have always been engaging tax
          agents to do it on their behalf.
        </p>

        <p>
          Tinkertax is the product of our winning idea, and we have successfully
          piloted it for trial with IRAS and taxpayers. Since then, many
          business owners and accounting firms have gone on to use our website
          to compute their taxes.
        </p>

        <p className="my-4 text-base text-gray-800 dark:text-gray-300">
          Our mission is to make it more cost and time efficient for everyone.
          So let’s rethink the way we run our business by using technology.
          Let’s get started!
        </p>
      </section>

      <div className="w-full flex justify-center my-4">
        <a
          href="https://app.tinkertax.com/users/sign_up"
          className="bg-primary-500 text-white rounded py-4 px-10 font-bold"
          target="_blank"
          rel="noreferrer"
        >
          Try Now
        </a>
      </div>
    </Layout>
  );
};

export default AboutPage;
